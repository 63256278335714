<template>
  <VCard class="px-9 py-6">
    <p class="page_title">
      Выбрать тариф
    </p>
    <VTextField
      v-model="search"
      class="search-width"
      color="success"
      dense
      outlined
      placeholder="Введите название тарифа"
      prepend-inner-icon="mdi-magnify"
      @input="searchTariff"
    />
    <VList
      ref="list"
      class="overflow-y-auto br-grey py-0"
      max-height="292"
    >
      <VListItemGroup
        v-if="filteredTariffs && filteredTariffs.length"
        v-model="selected"
        color="success"
      >
        <div v-for="(tariff, index) in filteredTariffs" :key="index">
          <VListItem :id="`el${tariff.id}`" :value="tariff.id">
            <VListItemTitle class="font-weight-bold" v-text="tariff.title" />
          </VListItem>
          <VDivider v-if="index < (filteredTariffs.length - 1)" class="mx-0 px-0" />
        </div>
      </VListItemGroup>
      <VListItem v-else class="text-center py-10">
        <VListItemTitle> {{ noDataText }} </VListItemTitle>
      </VListItem>
    </VList>
    <div class="d-flex justify-center mt-10 mb-4">
      <VBtn
        class="default-btn cancel-btn elevation-0 mr-3"
        color="#E0E0E0"
        x-large
        @click="$emit('close')"
      >
        Отменить
      </VBtn>
      <VBtn
        class="default-btn save-changes-btn elevation-0"
        color="success"
        :disabled="!selected"
        x-large
        @click="save"
      >
        Включить тариф в городе
      </VBtn>
    </div>
  </VCard>
</template>

<script>
import { fetchHotDeliveryTariffs, patchCity } from '@/api/api';

export default {
  props: {
    cityPk: {
      type: [String, Number],
      required: true,
    },
    tariffId: {
      type: Number,
      default: null,
    },
  },
  emits: ['close', 'update'],
  data() {
    return {
      tariffs: [],
      filteredTariffs: [],
      selected: null,
      search: '',
      noDataText: '',
    };
  },
  mounted() {
    if (this.tariffId) {
      this.selected = this.tariffId;
    }
    const params = {
      limit: 100,
      is_active: true,
    };
    this.noDataText = 'Загрузка данных...';
    fetchHotDeliveryTariffs(params).then((response) => {
      this.tariffs = response.data;
      this.filteredTariffs = response.data;
    }).finally(() => {
      if (this.selected) {
        this.scrollTo(this.selected);
      }
      this.noDataText = 'Нет данных';
    });
  },
  methods: {
    scrollTo(elementId) {
      this.$vuetify.goTo(`#el${elementId}`, { container: this.$refs.list });
    },
    searchTariff(search) {
      if (search) {
        this.filteredTariffs = this.tariffs.filter(
          (tariff) => tariff.title.toLowerCase().startsWith(search),
        );
        const searchTariff = this.filteredTariffs.reduce(
          (a, b) => (a.title.length <= b.title.length ? a : b),
        );
        this.selected = searchTariff.id || null;
        this.scrollTo(searchTariff.id);
      } else {
        this.selected = null;
        this.filteredTariffs = this.tariffs;
      }
    },
    save() {
      patchCity(this.cityPk, { hot_tariff_id: this.selected }).then(() => {
        this.$emit('update');
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.default-btn {
  border-radius: 12px;
}
.search-width {
  width: 410px;
}
.br-grey {
  border: 1px solid #E7E7E7;
  border-radius: 0px;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: #F4F4F4;
  border-left: 0.5px solid #E7E7E7;
}
::-webkit-scrollbar-thumb {
  background-color: #C7CACE;
  border: 3px solid transparent;
  border-radius: 30px;
  background-clip: content-box;
}
</style>
